// import logo from './logo.svg';
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/Home Page/Home';
import Aboutpage from './Components/About Page/Aboutpage';
import Careers from './Components/Careers/Careers';
import Menufix from './Components/Menufix/Menufix';
import Products from './Components/Products/Products';
import Blogs from './Components/Blogs/Blogs';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Menufix />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/about' element={<Aboutpage />}></Route>
          <Route exact path='/product' element={<Products />}></Route>
          <Route exact path='/careers' element={<Careers />}></Route>
          <Route exact path='/blogs' element={<Blogs />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
