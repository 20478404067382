import React from "react"
import Banner from "./Banner"
import Aboutsection from "./Aboutsection"
import Images from "./Images"
import Service from "./Service"
// import Ourcounter from "./Ourcounter"
// import Imageslides from "./Imageslides"
// import { Contact } from "./Contact"
import Testimonials from "./Testimonials"


function Home() {
    return (
        <>
            <Banner />
            <Aboutsection />
            <Images />
            <Service />
            <Testimonials />
            {/* <Ourcounter /> */}
            {/* <Imageslides /> */}
            {/* <Contact /> */}
        </>
        
    )
}
export default Home