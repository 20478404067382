import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import aboutHomeSecImg from '../Assests/Homeimages/abouthomesection.jpeg'
import './Aboutsection.css'

function Aboutsection() {
    const [content, setContent] = useState('');
    return (
        <Container fluid>
            <Row style={{ padding: '20px 30px' }}>
                <Col xs={12} sm={12} md={6}>
                    <h6 className="about-heading" style={{ fontSize: '30px', textAlign: 'left' }}>About Us</h6>
                    <p style={{ textAlign: 'left' }}>Our range of services include tailored outsourced recruitment, search and talent ‘mapping’ to role requirements, recruitment projects, temporary and contract recruitment through to human resource support services and career advice.</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <button onClick={() => setContent(
                            <p className="our-vision" >Our vision is to be living in this moment and planning for our – and our client’s success. We will always be looking for new ways we can bring value to our clients and continue to be recognized as a creative consultancy that passionately designs and delivers innovative solutions.</p>
                        )} className="btn btn-primary mx-2">Our Vision</button>
                        <button onClick={() => setContent(
                            <p className="our-vision" >Our mission is to help companies accelerate adoption of new technologies, untangle complex issues that always emerge during evolution, and orchestrate ongoing innovation. To never lose sight of our fundamental values, core competencies and what is best for our clients as we continue to learn and adap</p>
                        )} className="btn btn-primary mx-2">Our Mission</button>
                        {/* <button onClick={() => setContent(
                            <p className="our-vision">Machine Learning of the world</p>
                        )} className="btn btn-primary mx-2">Our Values</button> */}
                        <button onClick={() => setContent(
                            <p className="our-vision">Know about Our Team</p>
                        )} className="btn btn-primary mx-2">Meet the Team</button>
                    </div>
                    <p>{content}</p>
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <img src={aboutHomeSecImg} alt="aboutHomeImage" style={{ height: 'auto', width: '88%', borderRadius: '20px' }} />
                </Col>
            </Row>
        </Container>
    )
}
export default Aboutsection