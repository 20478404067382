import React from 'react'
import products from '../Assests/bannerimages/products.png'

function Products() {
  return (
    <div style={{marginTop: '70px'}}>
      <img src={products} alt="careers" style={{height: 'auto', width: '100%'}}/>
    </div>
  )
}

export default Products
