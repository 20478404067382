import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap";
// import imagecard1 from '../Assests/Homeimages/HumanoidRobot.jpeg';
import imagecard1 from '../Assests/Homeimages/card-1.jpg';
import imagecard2 from '../Assests/Homeimages/card-2.jpg';
import imagecard3 from '../Assests/Homeimages/card-3.jpg';
import imagecard4 from '../Assests/Homeimages/card-4.jpg';

// import imagecard3 from '../Assests/Homeimages/Humanoid(Ai+vlsi+robot).png';

import './Images.css'

function Images() {
    const catageorytypes = [
        { ImageUrl: imagecard1 },
        { ImageUrl: imagecard2 },
        { ImageUrl: imagecard3 },
        { ImageUrl: imagecard4 },
    ]
    return (
        <Container fluid>
            <Row className="p-3">
                <h4 className="p-5 cata-heading">SERVICES </h4>
                {catageorytypes.map((catageorytype, index) => (
                    <Col key={index} sm={6} xs={6} md={3} className="pb-3">
                        <Image className="zoomImage" src={catageorytype.ImageUrl} style={{ height: 'auto', width: '83%' }} />
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
export default Images