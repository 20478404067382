import React from "react";
import './Header.css'
import { Container, Row, Col } from 'react-bootstrap'
import facebook from '../Assests/Homeassests/Facebook.svg'
import instagram from '../Assests/Homeassests/Instagram.svg'
import linkedin from '../Assests/Homeassests/LinkedIn.svg'
import pinterest from '../Assests/Homeassests/Pinterest.svg'
import twitter from '../Assests/Homeassests/Twitter.svg'
import youtube from '../Assests/Homeassests/YouTube.svg'
import { IoMdMailOpen } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";

function Header() {
    return (
        <Container className='top' fluid style={{ backgroundColor: '#818181' }}>
            <Row>
                <Col className='social-icons py-2' xs={12} sm={12} md={4} lg={6}>
                    <a href="#"><img src={facebook} alt="facebook" /></a>
                    <a href="#"><img src={instagram} alt="instagram" /></a>
                    <a href="#"><img src={linkedin} alt="linkedin" /></a>
                    <a href="#"><img src={twitter} alt="twitter" /></a>
                    <a href="#"><img src={youtube} alt="youtube" /></a>
                    <a href="#"><img src={pinterest} alt="pinterest" /></a>
                </Col>
                <Col className='social-icons-buttons py-2' xs={12} sm={12} md={4} lg={6} >
                    <a href="mailto:admin@aagnait.com" className='text-decoration-none text-dark fw-bold fs-6 m-1'> <IoMdMailOpen  style={{color: '#e74926'}} className='mx-2 fs-5 fw-bold' />admin@aagnait.com</a>
                    <a href="https://api.whatsapp.com/send?phone=9197565777" className='text-decoration-none text-dark fw-bold fs-6 m-1'><FaPhoneAlt style={{color: '#e74926'}} className='mx-2 fs-5 fw-bold' />(919) 756-5777</a>
                </Col>
            </Row>
        </Container>
    )
}

export default Header