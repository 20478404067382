import React from 'react'
import careers from '../Assests/Homeimages/Careers-Image.jpg'

function Careers() {
  return (
    <div style={{marginTop: '70px'}}>
      <img src={careers} alt="careers" style={{height: 'auto', width: '100%'}}/>
    </div>
  )
}

export default Careers
