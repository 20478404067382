import React from 'react'
import Header from '../Header/Header'
import Menubar from '../Navbar/Navbar'

function Menufix() {
  return (
    <div className='fixed-top'>
      <Header />
      <Menubar />
    </div>
  )
}

export default Menufix
