import React from "react";
import './Footer.css'
import { Container, Row, Col } from "react-bootstrap";
import facebook from '../Assests/Homeassests/Facebook.svg'
import instagram from '../Assests/Homeassests/Instagram.svg'
import linkedin from '../Assests/Homeassests/LinkedIn.svg'
import pinterest from '../Assests/Homeassests/Pinterest.svg'
import twitter from '../Assests/Homeassests/Twitter.svg'
import youtube from '../Assests/Homeassests/YouTube.svg'
import { FaDesktop } from "react-icons/fa";
import { GrTechnology } from "react-icons/gr";
import { IoMdMailOpen } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";


function Footer() {
    return (
        <Container fluid className='text-white' style={{ backgroundColor: '#363A36' }}>
            <Row className='p-4'>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <div style={{ textAlign: 'left' }}>
                        <a href="#" style={{ fontStyle: 'italic', fontSize: '30px', fontWeight: 'bold', color: '#e74926', textDecoration: 'underline' }}><h2>AAGNAIT</h2></a>
                        <a href="#" style={{ textDecoration: 'none', color: 'white' }}><p><FaPhoneAlt className='mx-2' style={{ color: '#ff4b33' }} />(919) 756-5777</p></a>
                        <a href="#" style={{ textDecoration: 'none', color: 'white' }}><p><IoMdMailOpen className='mx-2' style={{ color: '#ff4b33' }} />admin@aagnait.com</p></a>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className='px-5' style={{ textAlign: 'left' }}>
                    <h4 className='footer-heading' style={{ color: '#ff4b33' }}>Address</h4>
                    <p>7912 Cottonwood Ln West<br />
                        Des Monies,<br />
                        Iowa 50266<br />
                    </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <ul style={{ listStyleType: 'none', textAlign: 'left' }}>
                        <h4 className='footer-heading' style={{ color: '#ff4b33' }}>Company</h4>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="/About"><li><FaDesktop style={{ marginRight: '10px', color: '#ff4b33' }} />About Us</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="/Careers"><li><FaDesktop style={{ marginRight: '10px', color: '#ff4b33' }} />Careers</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><FaDesktop style={{ marginRight: '10px', color: '#ff4b33' }} />Awards & Recognisation</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><FaDesktop style={{ marginRight: '10px', color: '#ff4b33' }} />CSR Programs</li></a>
                        {/* <a style={{ textDecoration: 'none', color: 'white' }} href="/hireatrainer"><li><FaDesktop style={{ marginRight: '10px', color: '#ff4b33' }} />Hire a Trainer</li></a> */}
                    </ul>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <ul style={{ listStyleType: 'none', textAlign: 'left' }}>
                        <h4 className='footer-heading' style={{ color: '#ff4b33' }}>Corporate</h4>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><GrTechnology style={{ marginRight: '10px', color: '#ff4b33' }} />Events</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><GrTechnology style={{ marginRight: '10px', color: '#ff4b33' }} />Blogs</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><GrTechnology style={{ marginRight: '10px', color: '#ff4b33' }} />case studies</li></a>
                        <a style={{ textDecoration: 'none', color: 'white' }} href="#"><li><GrTechnology style={{ marginRight: '10px', color: '#ff4b33' }} />webinars</li></a>
                        {/* <a style={{ textDecoration: 'none', color: 'white' }} href="blogs"><li><GrTechnology style={{ marginRight: '10px', color: '#ff4b33' }} />Blogs</li></a> */}
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row className='pb-4'>
                <Col xs={12} sm={12} md={6} lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href='#' style={{ textDecoration: 'none', color: 'white' }}><FaRegCopyright /> Copyright 2014 AAGNAIT Inc</a>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href='#' style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy | Terms & Conditions</a>
                </Col>
                <Col className='social-icons py-2' xs={12} sm={12} md={6} lg={4}>
                    <a href="#"><img src={facebook} alt="facebook" /></a>
                    <a href="#"><img src={instagram} alt="instagram" /></a>
                    <a href="#"><img src={linkedin} alt="linkedin" /></a>
                    <a href="#"><img src={twitter} alt="twitter" /></a>
                    <a href="#"><img src={youtube} alt="youtube" /></a>
                    <a href="#"><img src={pinterest} alt="pinterest" /></a>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer