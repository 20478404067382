import React from "react"
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import banner1 from '../Assests/bannerimages/Software-Image-2.jpg'
import './banners.css'


function Banner() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} style={{marginTop: '70px'}}>
      <Carousel.Item>
        <img src={banner1} alt="banner1" style={{ height: 'auto', width: '100%' }} />
        {/* <Carousel.Caption>
          <h3>MANENDRA</h3>
          <p>SOFTWARE TESTER</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  )
}
export default Banner