import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import './Service.css'
// import imagecard1 from '../Assests/Homeimages/HumanoidRobot.jpeg';

function Service() {

    const servicetypes = [
        { title: 'Application Development', content: 'Enhance your Custom application development using our robust technology and architecture.' },
        { title: 'Product Development & Maintenance', content: 'With our holistic approach, companies are able to harmonize people, process and solutions.' },
        { title: 'Big Data & Analytics', content: 'Transforming Big Data into actionable insights.' },
        { title: "", content: "" },
        { title: 'E-Commerce', content: 'Our eCommerce consultants can provide you with hands-on guidance on everything you need to build, grow, and accelerate your business.' },
        { title: 'Dedicated Development Center', content: 'We build dedicated software development centers to engage exclusively in the customers’ projects.' },
    ]

    return (
        <Container fluid style={{ backgroundColor: '#f8f9fa', padding: '40px' }}>
            {/* <h6 className="about-heading" style={{fontSize: '30px'}}>SERVICES</h6> */}
            <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
                {servicetypes.map((servicetype, index) => (
                    <Col key={index} xs={12} sm={12} md={4} className="service-card" style={{ borderRadius: '20px', padding: '20px' }}>
                        <h3 style={{ textAlign: 'left', fontFamily: 'Montserrat', fontSize: '30px', fontWeight: '500'  }}>{servicetype.title}</h3>
                        <p style={{ fontSize: '18px', fontFamily: 'Roboto', fontWeight: '400', textAlign: 'left' }}>{servicetype.content}</p>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
export default Service