import React from "react";
import { NavDropdown, Nav, Navbar, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Navbar.css'


function Menubar() {
  return (
    <Navbar id="main-nav" expand="lg" style={{ backgroundColor: '#ededed' }}>
      <Container>
        <Navbar.Brand href="#home" style={{ fontFamily: 'Roboto, sans-serif',  fontSize: '30px', fontWeight: 'bold', color: '#e74926' }}>AAGNAIT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} href="#home">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} href="#link">About Us</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/3.1">Staff Augmentation</NavDropdown.Item>
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/3.2">RPO Advantages</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Solutions" id="basic-nav-dropdown">
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/4.1">Application Development</NavDropdown.Item>
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/4.2">Product Development & Maintenance</NavDropdown.Item>
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/4.3">Big Data & Analytics</NavDropdown.Item>
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/4.4">E-commerce</NavDropdown.Item>
              <NavDropdown.Item className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '2vh', color: '#000' }} as={Link} to="#" href="#action/4.4">Dedicated Development Center</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/product" className='sliding-middle-out' style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} href="#link">Products</Nav.Link>
            <Nav.Link as={Link} to="/careers" style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} className='sliding-middle-out' href="#link">Careers</Nav.Link>
            <Nav.Link as={Link} to="/blogs" style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} className='sliding-middle-out' href="#link">Blogs</Nav.Link>
            {/* <Nav.Link as={Link} to="/Contact" style={{ fontWeight: '600', fontSize: '3vh', paddingRight: '50px', color: '#000' }} className='sliding-middle-out' href="#link">Contact</Nav.Link> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
export default Menubar