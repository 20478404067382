import React from 'react'
import aboutpage from '../Assests/Homeimages/About image.jpg'

function Aboutpage() {
  return (
    <div style={{marginTop: '70px'}}>
      <img src={aboutpage} alt='aboutpage' style={{height: 'auto', width: '100%'}}/>
    </div>
  )
}

export default Aboutpage