import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
// import logo from '../assests/logo.png'
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import image from '../Assests/testimages/img_avatar.png'



function Testimonials() {
  const tesimonials = [
    {
      cards: [{ image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.' },
      { image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.' },
      { image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.'  },
      ]
    },
    {
      cards: [{ image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.' },
      { image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.' },
      { image: image, name: 'Consulting Services Client	', review: 'Once again Aagnait has come to our aid in helping us find the correct candidates for each of our area during our busy season. It is always a pleasure doing business with Aagnait.'  },
      ]
    },
  ]

  return (
    <>
      <h1 className='text-center m-5 cata-heading' >Testimonials</h1>
      <Carousel>
        {tesimonials.map((testmonial, index) => (<Carousel.Item>
          <Container className='p-5'>
            <Row>
              {testmonial.cards.map((card, index) => (
                <Col xs={12} sm={12} md={4} key={index}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Body>
                      <img src={card.image} alt="image" style={{height: '100px', width: '100px', borderRadius: '50%'}}/>
                      <Card.Title style={{padding: '8px'}}>{card.name}</Card.Title>
                      <Card.Subtitle style={{padding: '8px'}} className="mb-2 text-muted">{card.profession}</Card.Subtitle>
                      <Card.Text style={{textAlign: 'justify'}}>{card.review}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default Testimonials
