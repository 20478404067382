import React from 'react'
import blogs from '../Assests/bannerimages/image-3.jpg'

function Blogs() {
  return (
    <div style={{marginTop: '70px'}}>
      <img src={blogs} alt="blogs" style={{height: 'auto', width: '100%'}}/>
    </div>
  )
}

export default Blogs
